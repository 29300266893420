// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import Vuetify from "vuetify";
import es from "vuetify/es5/locale/es";
import router from "./router";
import axios from "axios";
import "font-awesome/css/font-awesome.css";
import "./theme/default.styl";
//import VeeValidate from 'vee-validate';
import colors from "vuetify/es5/util/colors";
import Truncate from "lodash.truncate";
import VueGoogleCharts from "vue-google-charts";

//import spanish from 'vee-validate/dist/locale/es';
Vue.config.productionTip = false;
// Helpers
// Global filters
Vue.filter("truncate", Truncate);
/*Vue.use(VeeValidate, {
  locale: 'es',
  fieldsBagName: 'formFields'
});*/

Vue.use(Vuetify, {
    theme: {
        primary: "#4CAF50",
        secondary: "#8BC34A",
        accent: "#3F51B5",
        error: "#F44336",
        warning: "#FFC107",
        info: "#00BCD4",
        success: "#00BCD4"
    },
    options: {
        themeVariations: ["primary", "secondary", "accent"],
        extra: {
            mainToolbar: {
                color: "secondary"
            },
            sideToolbar: {},
            sideNav: "primary",
            mainNav: "primary lighten-1",
            bodyBg: ""
        }
    },
    lang: {
        locales: { es },
        current: "es"
    }
});
// googlecharts
Vue.use(VueGoogleCharts);
// Bootstrap application components
axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("jwtToken");
axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
Vue.prototype.$http = axios;
/* eslint-disable no-new */
var app = new Vue({
    el: "#app",
    router,
    components: { App },
    template: "<App/>"
});
