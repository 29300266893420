  <template>
  <v-layout row class="align-center layout px-4 pt-4 app--page-header">
    <div class="page-header-left">
      <h3 class="pr-3 page-title">{{ title }}</h3>
    </div>
    <v-spacer></v-spacer>
  </v-layout>
</template>

<script>
export default {
  props: ["title"]
};
</script>

<style lang="stylus">
.page-title {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: normal;
  color: #4caf50;
}
</style>
