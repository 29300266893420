const paths = [{
    path: "*",
    redirect: {
        path: "/404"
    }
},
{
    path: "/404",
    name: "NotFound",
    component: () =>
        import(`@/pages/NotFound.vue`)
},
{
    path: "/403",
    name: "AccessDenied",
    component: () =>
        import(`@/pages/Deny.vue`)
},
{
    path: "/500",
    name: "ServerError",
    component: () =>
        import(`@/pages/Error.vue`)
},
{
    path: "/login",
    name: "Login",
    component: () =>
        import(`@/pages/Login.vue`)
},
{
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
        import(`@/pages/ForgotPassword.vue`)
},
{
    path: "/",
    name: "Root",
    redirect: to => {
        return { name: "Login" };
    }
},
{
    path: "/dashboard",
    meta: { breadcrumb: true, private: true },
    name: "Dashboard",
    component: () =>
        import(`@/pages/Dashboard.vue`)
},
{
    path: "/users",
    meta: { breadcrumb: true, private: true },
    name: "Users",
    component: () =>
        import(`@/pages/UserList.vue`)
},
{
    path: "/user/form/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "UserForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/UserForm.vue`)
    }
},
{
    path: "/activities-progress",
    meta: { breadcrumb: true, private: true },
    name: "ActivityProgress",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ActivityProgress.vue`)
    }
},
{
    path: "/activities-progress-list",
    meta: { breadcrumb: true, private: true },
    name: "ActivityProgressList",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ActivityProgressList.vue`)
    }
},
{
    path: "/activity-progress/form/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "ActivityProgressForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ActivityProgressForm.vue`)
    }
},
{
    path: "/progress-from-activity/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "ProgressFromActivity",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ProgressFromActivity.vue`)
    }
},
{
    path: "/projects",
    meta: { breadcrumb: true, private: true },
    name: "Projects",
    component: () =>
        import(
            /* webpackChunkName: "routes" */
            /* webpackMode: "lazy" */
            `@/pages/ProjectList.vue`
        )
},
{
    path: "/projects/form/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "ProjectForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ProjectForm.vue`)
    }
},
{
    path: "/projects-progress",
    meta: { breadcrumb: true, private: true },
    name: "ProjectProgress",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ProjectProgress.vue`)
    }
},
{
    path: "/projects-progress-list",
    meta: { breadcrumb: true, private: true },
    name: "ProjectProgressList",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ProjectProgressList.vue`)
    }
},
{
    path: "/project-progress/form/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "ProjectProgressForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ProjectProgressForm.vue`)
    }
},
{
    path: "/progress-from-project/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "ProgressFromProject",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ProgressFromProject.vue`)
    }
},
{
    path: "/my-profile",
    meta: { breadcrumb: true, private: true },
    name: "MyProfile",
    props: true,
    components: {
        default: () =>
            import(`@/pages/MyProfile.vue`)
    }
},
{
    path: "/subprograms",
    meta: { breadcrumb: true, private: true },
    name: "Subprograms",
    props: true,
    components: {
        default: () =>
            import(`@/pages/SubprogramList.vue`)
    }
},
{
    path: "/subprogram/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "SubprogramForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/SubprogramForm.vue`)
    }
},
{
    path: "/reports-plan",
    meta: { breadcrumb: true, private: true },
    name: "ReportsPlan",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ReportPlanForm.vue`)
    }
},
{
    path: "/reports-projects",
    meta: { breadcrumb: true, private: true },
    name: "ReportsProjects",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ReportProjectsForm.vue`)
    }
},
{
    path: "/legal-process-list",
    meta: { breadcrumb: true, private: true },
    name: "LegalProcessList",
    props: true,
    components: {
        default: () =>
            import(`@/pages/LegalProcessList.vue`)
    }
},
{
    path: "/audit-list",
    meta: { breadcrumb: true, private: true },
    name: "AuditList",
    props: true,
    components: {
        default: () =>
            import(`@/pages/AuditList.vue`)
    }
},
{
    path: "/legal-process-form/:uuid?",
    meta: { breadcrumb: true, private: true },
    name: "LegalProcessForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/LegalProcessForm.vue`)
    }
},
{
    path: "/reports-legal-process",
    meta: { breadcrumb: true, private: true },
    name: "ReportLegalProcess",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ReportLegalProcessForm.vue`)
    }
},
{
    path: "/legal-process-graphics",
    meta: { breadcrumb: true, private: true },
    name: "GraphicsLegalProcess",
    props: true,
    components: {
        default: () =>
            import(`@/pages/GraphicsLegalProcess.vue`)
    }
},
{
    path: "/graphics-projects",
    meta: { breadcrumb: true, private: true },
    name: "GraphicsProjects",
    props: true,
    components: {
        default: () =>
            import(`@/pages/GraphicsProjects.vue`)
    }
},
// Breadcrumbs
{
    path: "/time-periods",
    meta: {
        breadcrumb: [{ name: "Mandatos Politicos" }],
        private: true
    },
    name: "TimePeriods",
    props: true,
    components: {
        default: () =>
            import(`@/pages/TimePeriodList.vue`)
    }
},
{
    path: "/time-period/:uuid?",
    name: "TimePeriodForm",
    props: true,
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            { name: ":uuid", active: true },
            { name: "Consejerias", link: "/entities/:uuid" }
        ],
        private: true
    },
    components: {
        default: () =>
            import(`@/pages/TimePeriodForm.vue`)
    }
},
{
    path: "/entities/:uuid?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            { name: ":uuid", link: "/time-period/:uuid" },
            { name: "Consejerias", active: true }
        ],
        private: true
    },
    name: "Entities",
    component: () =>
        import(`@/pages/EntityList.vue`)
},
{
    path: "/entity/form/:uuid?/:timeperiod?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            { name: ":uuid", link: "/time-period/:uuid" },
            { name: "Consejerias", link: "/entities/:uuid" },
            { name: ":uuid", active: true },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" }
        ],
        private: true
    },
    name: "EntityForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/EntityForm.vue`)
    }
},
{
    path: "/mandates/:timeperiod?/:entity?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", active: true }
        ],
        private: true
    },
    name: "Mandates",
    component: () =>
        import(`@/pages/MandateList.vue`)
},
{
    path: "/mandate/form/:uuid?/:timeperiod?/:entity?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            { name: ":uuid", active: true },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            }
        ],
        private: true
    },
    name: "MandateForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/MandateForm.vue`)
    }
},
{
    path: "/action-lines/:timeperiod?/:entity?/:mandate?",
    props: true,
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":uuid",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                active: true
            }
        ],
        private: true
    },
    name: "ActionLines",
    component: () =>
        import(`@/pages/ActionLineList.vue`)
},
{
    path: "/action-lines/form/:uuid?/:timeperiod?/:entity?/:mandate?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":uuid",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            },
            {
                name: ":uuid",
                active: true
            },
            {
                name: "Estrategias",
                link: "/strategies/:timeperiod/:entity/:mandate/:actionline"
            }
        ],
        private: true
    },
    name: "ActionLineForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ActionLineForm.vue`)
    }
},
{
    path: "/strategies/:timeperiod?/:entity?/:mandate?/:actionline?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":mandate",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            },
            {
                name: ":actionline",
                link: "/action-lines/form/:actionline/:timeperiod/:entity/:mandate"
            },
            {
                name: "Estrategias",
                active: true
            }
        ],
        private: true
    },
    name: "Strategies",
    component: () =>
        import(`@/pages/StrategyList.vue`)
},

{
    path: "/strategies/form/:uuid?/:timeperiod?/:entity?/:mandate?/:actionline?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":mandate",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            },
            {
                name: ":actionline",
                link: "/action-lines/form/:actionline/:timeperiod/:entity/:mandate"
            },
            {
                name: "Estrategias",
                link: "/strategies/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: ":uuid",
                active: true
            },
            {
                name: "Actividades",
                link: "/activities/:timeperiod/:entity/:mandate/:actionline/:strategy"
            }
        ],
        private: true
    },
    name: "StrategyForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/StrategyForm.vue`)
    }
},
{
    path: "/activities/:timeperiod?/:entity?/:mandate?/:actionline?/:strategy?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":mandate",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            },
            {
                name: ":actionline",
                link: "/action-lines/form/:actionline/:timeperiod/:entity/:mandate"
            },
            {
                name: "Estrategias",
                link: "/strategies/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: ":strategy",
                link: "/strategies/form/:strategy/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: "Actividades",
                active: true
            }
        ],
        private: true
    },
    name: "Activities",
    component: () =>
        import(`@/pages/ActivityList.vue`)
},
{
    path: "/activity/:timeperiod?/:entity?/:mandate?/:actionline?/:strategy?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":mandate",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            },
            {
                name: ":actionline",
                link: "/action-lines/form/:actionline/:timeperiod/:entity/:mandate"
            },
            {
                name: "Estrategias",
                link: "/strategies/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: ":strategy",
                link: "/strategies/form/:strategy/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: "Actividades",
                link: "/activities/:timeperiod/:entity/:mandate/:actionline/:strategy"
            },
            {
                name: "Actividad",
                active: true
            }
        ],
        private: true
    },
    name: "Activity",
    props: true,
    components: {
        default: () =>
            import(`@/pages/Activity.vue`)
    }
},
{
    path: "/activities/form/:uuid?/:timeperiod?/:entity?/:mandate?/:actionline?/:strategy?",
    meta: {
        breadcrumb: [
            { name: "Mandatos Politicos", link: "/time-periods" },
            {
                name: ":timeperiod",
                link: "/time-period/:timeperiod"
            },
            { name: "Consejerias", link: "/entities/:timeperiod" },
            {
                name: ":entity",
                link: "/entity/form/:entity/:timeperiod"
            },
            { name: "Mandatos", link: "/mandates/:timeperiod/:entity" },
            {
                name: ":mandate",
                link: "/mandate/form/:mandate/:timeperiod/:entity"
            },
            {
                name: "Lineas de Acción",
                link: "/action-lines/:timeperiod/:entity/:mandate"
            },
            {
                name: ":actionline",
                link: "/action-lines/form/:actionline/:timeperiod/:entity/:mandate"
            },
            {
                name: "Estrategias",
                link: "/strategies/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: ":strategy",
                link: "/strategies/form/:strategy/:timeperiod/:entity/:mandate/:actionline"
            },
            {
                name: "Actividades",
                link: "/activities/:timeperiod/:entity/:mandate/:actionline/:strategy"
            },
            {
                name: ":uuid",
                active: true
            }
        ],
        private: true
    },
    name: "ActivityForm",
    props: true,
    components: {
        default: () =>
            import(`@/pages/ActivityForm.vue`)
    }
}
];

export default paths;
