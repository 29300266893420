<template>
  <div id="appRoot">
    <div id="test-env-msg" v-if="isTestEnv">
      Ambiente de pruebas...
    </div>
    <template v-if="$route.meta.private">
      <v-app id="inspire" class="app">
        <app-drawer class="app--drawer"></app-drawer>
        <app-toolbar :avatar="avatar" class="app--toolbar"></app-toolbar>
        <v-content>
          <page-header :title="title"></page-header>
          <div class="page-wrapper">
            <router-view></router-view>
          </div>
          <v-footer height="auto" class="white pa-3 app--footer">
            <span class="caption" v-if="timePeriod">Mandato político: {{ timePeriod }}</span>
            <v-spacer></v-spacer>
          </v-footer>
        </v-content>
        <!-- Go to top -->
        <app-fab></app-fab>
      </v-app>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
    <v-snackbar :timeout="3000" bottom right :color="snackbar.color" v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn dark flat @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";
import AppFab from "@/components/AppFab";
import PageHeader from "@/components/PageHeader";
import AppEvents from "./event";
export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
    PageHeader
  },
  data: () => ({
    isTestEnv: false,
    expanded: true,
    snackbar: {
      show: false,
      text: "",
      color: ""
    },
    title: "",
    avatar: "",
    timePeriod: null
  }),
  created() {
    if (window.location.href.includes('pyp-pruebas')) {
      this.isTestEnv = true;
    }

    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;
  }
};
</script>


<style lang="stylus">
#test-env-msg {
  background: red;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1000;
  color: white;
  font-size: revert;
  font-weight: bold;
  font-style: italic;
  padding: 0px 10px;
  display: inline-block;
}

.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

.page-wrapper {
  min-height: calc(100vh - 64px - 50px - 81px);
}

.logo-link {
  color: white;
  text-decoration: none;

  & > div {
    display: inline-block;
  }
}

.report-title {
  font-size: 1.45rem;
  font-weight: 300;
  letter-spacing: normal;
  color: #4caf50;
}

.theme--light.v-text-field--outline > .v-input__control > .v-input__slot, .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  background: #fff !important;
}

.v-text-field--outline.v-input--is-focused > .v-input__control > .v-input__slot, .v-text-field--outline.v-input--has-state > .v-input__control > .v-input__slot {
  border: 1px solid currentColor;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border: 1px solid #e5e5e5;
}

table.v-table tbody td {
  font-weight: 400;
  font-size: 1rem;
  color: #666;
  vertical-align: top;
  padding: 0.75rem 1.45rem !important;
}

.theme--light.v-table thead th {
  color: #2d9437;
  font-size: 1.15rem;
  text-transform: uppercase;
  background: #eee;
}

.theme--light.v-icon {
  color: #3f51b5;
}

.vue-dropzone {
  border: 1px solid #e5e5e5;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
}

.progress-current-activity-data .activity-text {
  padding: 1.15rem;
  width: 100%;
  color: #666;
}
</style>
