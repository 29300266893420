export default [
    {
        name: "APP_LOGIN_SUCCESS",
        callback: function(e) {
            this.$router.push({ path: "dashboard" });
        }
    },
    {
        name: "APP_LOGOUT",
        callback: function(e) {
            var self = this;
            this.$http
                .post("/api/auth/logout")
                .then(function(response) {
                    self.snackbar = {
                        show: true,
                        color: "green",
                        text: "Ha cerrado su sesión."
                    };
                    localStorage.removeItem("jwtToken");
                    self.$router.replace({ path: "/login" });
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    },
    {
        name: "SHOW_SNACKBAR",
        callback: function(e) {
            this.snackbar = {
                show: true,
                color: e.color ? e.color : "green",
                text: e.text
            };
        }
    },
    {
        name: "SET_TITLE",
        callback: function(e) {
            this.title = e;
        }
    },
    {
        name: "SET_AVATAR",
        callback: function(e) {
            this.avatar = e;
        }
    },
    {
        name: "SET_TIMEPERIOD",
        callback: function(e) {
            this.timePeriod = e;
        }
    },
    {
        name: "APP_PAGE_LOADED",
        callback: function(e) {}
    },
    {
        name: "APP_AUTH_FAILED",
        callback: function(e) {
            this.$router.push("/login");
            this.$message.error("Token has expired");
        }
    },
    {
        name: "APP_BAD_REQUEST",
        // @error api response data
        callback: function(msg) {
            this.$message.error(msg);
        }
    },
    {
        name: "APP_ACCESS_DENIED",
        // @error api response data
        callback: function(msg) {
            this.$message.error(msg);
            this.$router.push("/forbidden");
        }
    },
    {
        name: "APP_RESOURCE_DELETED",
        // @error api response data
        callback: function(msg) {
            this.$message.success(msg);
        }
    },
    {
        name: "APP_RESOURCE_UPDATED",
        // @error api response data
        callback: function(msg) {
            this.$message.success(msg);
        }
    },
    {
        name: "ALREADY_LOGGED_IN",
        callback: function(e) {
            this.$router.push({ path: "dashboard" });
        }
    },
    {
        name: "NOT_LOGGED_IN",
        callback: function(e) {
            //localStorage.removeItem('jwtToken');
            this.$router.push({ path: "login" });
        }
    }
];
