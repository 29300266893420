import Vue from "vue";
import VueRouter from "vue-router";
import paths from "./paths";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);
const router = new VueRouter({
    base: "/",
    mode: "hash",
    linkActiveClass: "active",
    routes: paths
});

// router gards
router.beforeEach((to, from, next) => {
    NProgress.start();
    Vue.nextTick(function() {
        if (to.name != "Login" && !localStorage.getItem("jwtToken")) {
            next({ path: "login" });
        }
    });
    next();
});

router.afterEach((to, from) => {
    // ...
    NProgress.done();
});
export default router;
